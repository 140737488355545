
@media only screen and (max-width:800px) {
  #day-table tbody,
  #day-table tr,
  #day-table td {
    display: block;
  }
  #day-table thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  #day-table td {
    position: relative;
    padding-left: 60%;
    border: none;
    border-bottom: 1px solid #eee;
    margin: 1% 5% 1% 5%;
  }
  #day-table td:before {
    content: attr(data-label);
    position: absolute;
    left: 6px;
    font-weight: bold;
  }
  #day-table tr {
    border: 2px solid #3684ef;
    border-radius: 5px;
    margin-bottom: 6%;
  }
  .btn-booking {
    margin-left: -220%;
  }
}